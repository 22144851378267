body {
  max-height: 100vh;
}
.pdfHeader {
  font-weight: bold;
  padding: 5px 0px;
}

@media print {
  #root {
    height: 90vh;
    overflow: hidden;
  }

  html,
  body {
    background-color: #fff;
    margin: 0;
  }

  @page {
    margin: 0;
  }

  .printable * {
    visibility: visible;
    page-break-before: none;
    page-break-after: none;
    page-break-inside: avoid;
  }
}
