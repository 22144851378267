@font-face {
  font-family: 'Gotham';
  src: url('../public//fonts/GothamBook.otf') format('opentype'),
    url('../public/fonts/GothamBook.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Gotham';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Gotham';
}
